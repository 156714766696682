import React, {useEffect, useState} from 'react';
import {  FaFacebookMessenger } from 'react-icons/fa';
import { FaFacebook } from "react-icons/fa6";

import { SiThreads } from 'react-icons/si';
import { IoCloseOutline } from 'react-icons/io5'; 

import { RiLink, RiMailLine , RiTwitterXFill, RiWhatsappLine} from "react-icons/ri";


import './ShareModal.css';

const ShareModal = ({ isOpen, onClose, track, albumUrl, onCopyLink  }) => {
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
      if (isOpen) {
        setIsAnimating(true);
      }
    }, [isOpen]);
  
    const handleClose = () => {
      setIsAnimating(false);
      setTimeout(onClose, 300); // Espera a que termine la animación antes de cerrar
    };
  
    if (!isOpen && !isAnimating) return null;
  
    const shareText = `Escucha "${track.title}" de Arena Negra`;
  const shareLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(albumUrl)}`,
    whatsapp: `https://wa.me/?text=${encodeURIComponent(shareText + ' ' + albumUrl)}`,
    twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(albumUrl)}`,
    messenger: `https://www.facebook.com/dialog/send?link=${encodeURIComponent(albumUrl)}&app_id=YOUR_FACEBOOK_APP_ID`,
    threads: `https://www.threads.net/`, // Threads no tiene API de compartir directa
    email: `mailto:?subject=${encodeURIComponent(shareText)}&body=${encodeURIComponent(albumUrl)}`
  };

  const copyLink = () => {
    navigator.clipboard.writeText(albumUrl).then(() => {
      onCopyLink();
      handleClose();
    });
  };

  return (
    <div className={`share-modal-backdrop ${isAnimating ? 'open' : ''}`} onClick={handleClose}>
      <div className={`share-modal ${isAnimating ? 'open' : ''}`} onClick={e => e.stopPropagation()}>
        <div className="share-header"> 
            <h2>Compartir</h2>
            <button  onClick={handleClose}  className="close-button"><IoCloseOutline/></button>
        </div>
        <div className="share-options">
          <div>
            <a href={shareLinks.facebook} target="_blank" rel="noopener noreferrer" className="share-option">
            <FaFacebook /> 
            </a>
            <div>Facebook</div>
          </div>
          <div>
              <a href={shareLinks.whatsapp} target="_blank" rel="noopener noreferrer" className="share-option">
                <RiWhatsappLine />
              </a>
              <div>WhatsApp</div>
          </div>
          <div>
              <button onClick={copyLink} className="share-option">
                <RiLink />  
              </button>
              <div>Copiar Link</div>
          </div>
          <div>
              <a href={shareLinks.twitter} target="_blank" rel="noopener noreferrer" className="share-option">
                <RiTwitterXFill />
              </a>
              <div>Twitter</div>
          </div>
          <div>
              <a href={shareLinks.messenger} target="_blank" rel="noopener noreferrer" className="share-option">
                <FaFacebookMessenger />
              </a>
              <div>Messenger</div>
          </div>
          <div>
              <a href={shareLinks.threads} target="_blank" rel="noopener noreferrer" className="share-option">
                <SiThreads />
              </a>
              <div>Threads</div>
          </div>
          <div>
              <a href={shareLinks.email} className="share-option">
                <RiMailLine />
              </a>
              <div>Email</div>
          </div>
        </div>
         
      </div>
    </div>
  );
};

export default ShareModal;