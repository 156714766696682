// src/contexts/MusicPlayerProvider.jsx 16 oct
import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useQuery, gql } from "@apollo/client";

const MusicPlayerContext = createContext();

const GET_LATEST_ALBUM = gql`
  query GetLatestAlbum {
    albumes(where: { orderby: { field: DATE, order: DESC } })  {
      edges {
        node {
          id
          title
          date
          content
          slug
          status
          modified
          albumData {
            title
            releaseDate
            coverImage {
              node {
                sourceUrl
                title
              }
            }
            backgroundImage {
              node {
                sourceUrl
                title
              }
            }
            copyright
            phonogram
            platforms {
              __typename
              ... on AlbumDataPlatformsPlatformsDataLayout {
                urlPlatform {
                  target
                  title
                  url
                }
              }
            }
            tracks {
              __typename
              ... on AlbumDataTracksTrackListLayout {
                songName
                youtubeId
                lyrics
                trackDuration
              }
            }
          }
        }
      }
    }
  }
`;

export const MusicPlayerProvider = ({ children }) => {
  const [tracks, setTracks] = useState([]);
  const [albumData, setAlbumData] = useState(null);
  const [currentTrack, setCurrentTrack] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayerPlaying, setIsPlayerPlaying] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [player, setPlayer] = useState(null);  // Aquí definimos player
  const [albums, setAlbums] = useState([]);
const [currentAlbumIndex, setCurrentAlbumIndex] = useState(0);
  const { loading, error, data } = useQuery(GET_LATEST_ALBUM); 
 

  useEffect(() => {
    if (data && data.albumes.edges.length > 0) {
      const newAlbums = data.albumes.edges.map(edge => {
        const album = edge.node;
        const albumData = album.albumData;
        
        const tracks = albumData.tracks.map((track) => ({
          title: track.songName,
          artist: "Arena Negra",
          youtubeId: track.youtubeId,
          coverArt: albumData.coverImage.node.sourceUrl,
          lyrics: track.lyrics,
          duration: track.trackDuration
        }));
  
        return {
          id: album.id,
          title: album.title,
          date: album.date,
          tracks: tracks,
          albumData: albumData
        };
      });
  
      setAlbums(newAlbums);
      setCurrentAlbumIndex(0);  // Establece el primer álbum como el actual
  
      // Establece los tracks y albumData del primer álbum como los actuales
      if (newAlbums.length > 0) {
        setTracks(newAlbums[0].tracks);
        setAlbumData(newAlbums[0].albumData);
      }
    }
  }, [data]);
   
  const togglePlayer = useCallback(() => {
    setIsPlayerPlaying((prevState) => !prevState);  // Cambiamos el estado de play/pausa
    setIsPlaying(prevState => !prevState);
    if (!isPlayerPlaying) {
      setIsVideoPlaying(false);
    }
  }, [setIsPlayerPlaying, isPlayerPlaying]);

  const changeAlbum = (index) => {
    if (index >= 0 && index < albums.length) {
      const selectedAlbum = albums[index];
      setCurrentAlbumIndex(index);
      setTracks(selectedAlbum.tracks);
      setAlbumData(selectedAlbum.albumData);
      setCurrentTrack(0);  // Reinicia la pista al cambiar de álbum
    }
  };

  
  


  const value = {
    setPlayer,
    player,
    tracks,
    albumData,
    currentTrack,
    setCurrentTrack,
    isPlaying,
    setIsPlaying,
    isVideoPlaying,
    setIsVideoPlaying,
    isPlayerPlaying,  // Nuevo estado
    setIsPlayerPlaying,  // Nuevo setter
    togglePlayer,  // Nueva función
    albums,
  currentAlbumIndex,
  changeAlbum,
    loading,
    error
  };

  return (
    <MusicPlayerContext.Provider value={value}>
      {children}
    </MusicPlayerContext.Provider>
  );
};

export const useMusicPlayer = () => useContext(MusicPlayerContext);