// src/pages/album/AlbumTemplate.jsx
import React, { useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useQuery, gql } from "@apollo/client";
import Footer from '../../components/navigation/footer/Footer';
import Logo from '../../components/navigation/header/Logo';
import './album.css';
import Loading from '../../components/loading/Loading';  

const GET_ALBUM = gql`
  query GetAlbum($slug: String!) {
    albumes(where: {name: $slug}, first: 1) {
      edges {
        node {
          id
          title
          date
          content
          slug
          status
          modified
          albumData {
            title
            releaseDate
            coverImage {
              node {
                sourceUrl
                title
              }
            }
            backgroundImage {
                node {
                    sourceUrl
                    title
                }
            }
            copyright
            phonogram
            platforms {
              __typename
              ... on AlbumDataPlatformsPlatformsDataLayout {
                urlPlatform {
                  target
                  title
                  url
                }
              }
            }
            tracks {
                __typename
                ... on AlbumDataTracksTrackListLayout {
                    songName
                    youtubeId
                    lyrics
                    trackDuration
                }
            }
          }
        }
      }
    }
  }
`;

function AlbumTemplate() {
    const { slug } = useParams();
    const { loading, error, data } = useQuery(GET_ALBUM, {
        variables: { slug }
    });

    useEffect(() => {
        // Este efecto se ejecutará cada vez que se monte el componente
        window.scrollTo(0, 0);
    }, []);

    if (loading) return <Loading />;
    if (error) return <p>Error: {error.message}</p>;

    // Si no hay datos o el array de edges está vacío, redirigimos a la página de No encontrado
    if (!data || data.albumes.edges.length === 0) {
        return <Navigate to="/404" replace />;
    }

    const album = data.albumes.edges[0].node;
    const albumData = album.albumData;

    // Función para obtener la ruta de la imagen según el título de la plataforma
    const getPlatformImage = (title) => {
        const lowerTitle = title.toLowerCase();
        if (lowerTitle.includes('spotify')) {
            return "../images/music-platforms/spotify.svg";
        } else if (lowerTitle.includes('apple')) {
            return "../images/music-platforms/apple-music.svg";
        } else if (lowerTitle.includes('youtube')) {
            return "../images/music-platforms/youtube-music.svg";
        } else if (lowerTitle.includes('amazon')) {
            return "../images/music-platforms/amazon-music.svg";
        }
        // Puedes agregar más condiciones para otras plataformas
        return ""; // Retorna una imagen por defecto o cadena vacía si no hay coincidencia
    };

    const formatDate = (dateString) => {
        // Convertir la fecha a la zona horaria local
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        const day = localDate.getDate();
        const month = localDate.toLocaleString('es-ES', { month: 'long' });
        const year = localDate.getFullYear();

        return `${day} de ${month} de ${year}`;
    };
    const formattedDate = formatDate(albumData.releaseDate);

    const backgroundImageStyle = {
        backgroundImage: `url(${albumData.backgroundImage.node.sourceUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      };
 
      /* const tracks = [
        {
            title: "Canción 1",
            artist: "Arena Negra",
            youtubeId: "r36ihkYvHIM",
            coverArt: albumData.coverImage.node.sourceUrl,
            lyricsUrl: "https://ejemplo.com/letras/cancion1"
        },
        {
            title: "Canción 2",
            artist: "Arena Negra",
            youtubeId: "DvMDdB1aq-o",
            coverArt: albumData.coverImage.node.sourceUrl,
            lyricsUrl: "https://ejemplo.com/letras/cancion1"
        },
        // Añade más canciones según sea necesario
    ]; */

      
    return (
        <div className='listen-album' style={backgroundImageStyle}>
            <div className='background-frosted'>
                <div>
                    <header className="animate-header">
                        <Logo src="../arena-nerga-logo-horizontal.png" className="logo" />
                    </header>
                    <main className='music-platforms animate-content'>
                        <div className='album-details'>
                            <h1>{albumData.title}</h1>
                            <p>Publicado el {formattedDate}</p>
                        </div>
                        <ul>
                            <li className='album-cover'>
                                <figure>
                                    <img
                                        src={albumData.coverImage.node.sourceUrl}
                                        alt={albumData.coverImage.node.title || albumData.title}
                                    />
                                </figure>
                            </li>
                            <li className='platforms-list'>
                                {albumData.platforms.map((platform, index) => (
                                    <React.Fragment key={index}>
                                        <figure>
                                            <img
                                                src={getPlatformImage(platform.urlPlatform.title)}
                                                alt={platform.urlPlatform.title}
                                            />
                                        </figure>
                                        <a
                                            className='buttons'
                                            href={platform.urlPlatform.url}
                                            target={platform.urlPlatform.target}
                                            rel="noreferrer"
                                        >
                                            ESCUCHAR
                                        </a>
                                    </React.Fragment>
                                ))}
                            </li>
                        </ul>
                        <div className='album-details'>
                            <p>{albumData.copyright}<br />
                                {albumData.phonogram}</p>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div> 
             
        </div>
    );
}

export default AlbumTemplate;