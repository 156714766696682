/* Versión v5 */
import React, { useState, useCallback, useEffect } from 'react';
import YouTube from 'react-youtube';
import './CustomMusicPlayer.css';
import { IoPaperPlaneOutline, IoPauseOutline, IoPlaySharp, IoPlayBackSharp, IoPlayForwardSharp, IoDocumentTextOutline, IoAlbums, IoList } from "react-icons/io5";
import { VscSettings } from "react-icons/vsc";
import AlbumDetailsModal from './AlbumDetailsModal';
import ShareModal from './ShareModal';
import Notification from '../components/notifications/Notification';
import { useMusicPlayer } from '../contexts/MusicPlayerProvider';


const CustomMusicPlayer = ({ tracks, albumData }) => {
  const { isPlayerPlaying, setIsPlayerPlaying, isVideoPlaying, albums, currentAlbumIndex, changeAlbum } = useMusicPlayer();
  const [currentTrack, setCurrentTrack] = useState(0);
  const [player, setPlayer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [showShareModal, setShowShareModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [trackDurations, setTrackDurations] = useState({}); const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 980);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 980);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  

  const onReady = useCallback((event) => {
    console.log("Player is ready");
    setPlayer(event.target);
    const duration = event.target.getDuration();
    setTrackDurations(prev => ({
      ...prev,
      [tracks[currentTrack].youtubeId]: formatDuration(duration)
    }));
  }, [currentTrack, tracks]);

  useEffect(() => {
    if (isVideoPlaying && player) {
      player.pauseVideo();
      setIsPlayerPlaying(false);
    }
  }, [isVideoPlaying, player, setIsPlayerPlaying]);




  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  }; 

  
  const handleTrackSelect = useCallback((index) => {
    setCurrentTrack(index);
    setIsPlayerPlaying(true);
    if (player) {
      player.loadVideoById(tracks[index].youtubeId);
      // No necesitamos llamar a player.playVideo() aquí porque lo manejaremos en el efecto
    }
  }, [player, tracks, setIsPlayerPlaying]);

  useEffect(() => {
    if (player && isPlayerPlaying) {
      player.playVideo();
    }
  }, [currentTrack, player, isPlayerPlaying]);

  const handlePlayPause = useCallback(() => {
    if (player) {
      if (isPlayerPlaying) {
        player.pauseVideo();
      } else {
        player.playVideo();
      }
      setIsPlayerPlaying(!isPlayerPlaying);
    }
  }, [player, isPlayerPlaying, setIsPlayerPlaying]);

  const handleNextTrack = useCallback(() => {
    const nextIndex = (currentTrack + 1) % tracks.length;
    handleTrackSelect(nextIndex);
  }, [currentTrack, tracks.length, handleTrackSelect]);

  const handlePrevTrack = useCallback(() => {
    const prevIndex = (currentTrack - 1 + tracks.length) % tracks.length;
    handleTrackSelect(prevIndex);
  }, [currentTrack, tracks.length, handleTrackSelect]);

  const opts = {
    height: '0',
    width: '0',
    playerVars: {
      autoplay: 1,
    },
  };

  const handleModalOpen = (content) => {
    setModalContent(content);
    setShowModal(true);
    setShowMoreOptions(true);  // Cambiamos a true cuando se abre el modal
  };


  const handleModalClose = () => {
    setShowModal(false);
    setShowMoreOptions(false);  // Cambiamos a false cuando se cierra el modal
  };

  const handleMoreOptionsClick = () => {
    if (!isWideScreen) {
      handleModalOpen('songs');
    }
  };


  const backdropImagePlayer = {
    backgroundImage: `url(${albumData.coverImage.node.sourceUrl})`,
  };

  const handleShareClick = () => {
    setShowShareModal(true);
  };

  const handleCopyLink = () => {
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000); // Oculta la notificación después de 3 segundos
  };



  return (
    <>
      <div className={`custom-music-player ${isWideScreen ? '' : 'mobile-view'}`}>
        <div className='backdrop-music-player' style={backdropImagePlayer}></div>
        <div className={`player-info ${isWideScreen ? '' : 'mobile-view'}`}>
          <YouTube
            videoId={tracks[currentTrack].youtubeId}
            opts={opts}
            onReady={onReady}
            onStateChange={(event) => {
              if (event.data === YouTube.PlayerState.PLAYING) {
                setIsPlayerPlaying(true);
              } else if (event.data === YouTube.PlayerState.PAUSED) {
                setIsPlayerPlaying(false);
              } else if (event.data === YouTube.PlayerState.ENDED) {
                handleNextTrack();
              }
            }}
          />
          <img src={tracks[currentTrack].coverArt} alt="Album cover" className="cover-art" />
          <div className="track-info">
            <h3>{tracks[currentTrack].title}</h3>
            <p>{tracks[currentTrack].artist}</p>
          </div>
        </div>
        <div className="controls-container">
          <div className="main-controls">
            <button onClick={handlePrevTrack} disabled={!player} className="control-button">
              <IoPlayBackSharp />
            </button>
            <button onClick={handlePlayPause} disabled={!player} className="control-button play-pause">
              {isPlayerPlaying ? <IoPauseOutline /> : <IoPlaySharp />}
            </button>
            <button onClick={handleNextTrack} disabled={!player} className="control-button">
              <IoPlayForwardSharp />
            </button>
          </div>
        </div>
        <div className="secondary-controls">
        {isWideScreen ? (
            <>
              <button onClick={() => handleModalOpen('lyrics')} className="secondary-button">
                <IoDocumentTextOutline /> Letra
              </button>
              <button onClick={() => handleModalOpen('album')} className="secondary-button">
                <IoAlbums /> Álbum
              </button>
              <button onClick={() => handleModalOpen('songs')} className="secondary-button">
                <IoList /> Canciones
              </button>
              <button onClick={handleShareClick} className="secondary-button">
                <IoPaperPlaneOutline /> Compartir
              </button>
            </>
          ) : (
            <button
              onClick={showMoreOptions ? handleShareClick : handleMoreOptionsClick}
              className={`secondary-button ${isWideScreen ? null : 'narrow-screen'}`}
            >
              {showMoreOptions ? (
                <>
                  <IoPaperPlaneOutline /> 
                </>
              ) : (
                <>
                  <VscSettings />  
                </>
              )}
            </button>
          )}
        </div>

      </div>
      {showModal && (
        <div className={`modal-wrapper ${isWideScreen ? '' : 'mobile-view'}`}>
          <AlbumDetailsModal
            lyrics={tracks[currentTrack].lyrics}
            onClose={handleModalClose}
            title={tracks[currentTrack].title}
            duration={tracks[currentTrack].duration}
            tracks={tracks}
            albumData={albumData}
            currentTrackIndex={currentTrack}
            onTrackSelect={handleTrackSelect}
            isPlaying={isPlayerPlaying}
            onPlayPause={handlePlayPause}
            trackDurations={trackDurations}
            albums={albums}
            currentAlbumIndex={currentAlbumIndex}
            onAlbumSelect={changeAlbum}
            initialTab={modalContent}
            isWideScreen={isWideScreen}
   
            
          />
        </div>
      )}
      <ShareModal
        isOpen={showShareModal}
        onClose={() => setShowShareModal(false)}
        track={tracks[currentTrack]}
        albumUrl={window.location.href}
        onCopyLink={handleCopyLink}

      />
      <Notification
        message="Enlace copiado en el portapapeles"
        isVisible={showNotification}
      />
    </>
  );
};

export default CustomMusicPlayer;