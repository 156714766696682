// src/music-player/MusicPlayer.jsx
import React from 'react';
import { useMusicPlayer } from '../contexts/MusicPlayerProvider';
import Loading from '../components/loading/Loading';
import CustomMusicPlayer from './CustomMusicPlayer';

function MusicPlayer() {
    const { tracks, albumData, loading, error } = useMusicPlayer();

    if (loading) return <Loading />;
    if (error) return <p>Error: {error.message}</p>;
    if (!albumData || tracks.length === 0) return null;

    return (
        <CustomMusicPlayer tracks={tracks} albumData={albumData} />
    );
}

export default MusicPlayer;