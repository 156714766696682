import React from 'react';
import './fonticons.css';
import './icons.css'

const FontIcons = ({children}) => {
    return (
        <>{children}</>
    )
}

export default FontIcons
