import React, {useEffect} from 'react';
import Footer from '../../components/navigation/footer/Footer'; 
import './homepage.css';  
import Header from '../../components/navigation/header/Header';
import MyAlbums from './MyAlbums';
import Live from './Live';
import VideoEmbed from './VideoEmbed';
import Contact from './Contact';
import Bio from './Bio';

function HomePage() {
    
    useEffect(() => {
        document.title = "Arena Negra | músico uruguayo"
     }, []);
    return (
        <> 
        <Header />
        <Live />
        <MyAlbums />
        <VideoEmbed />
        <Bio />      
        <Contact />
        <Footer />
             
        </>
    )
}

export default HomePage
