import React from 'react';
import {Link} from "react-router-dom";

function Logo(props) {
    const {className, src} = props;
    return (
        <Link to='/'><figure><img src={`${src}`} alt="⚡ ARENA NEGRA" title='⚡ ARENA NEGRA' className={`${className}`}/></figure></Link>
    )
}

export default Logo
