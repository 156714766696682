import React, { useState, useEffect, useMemo, useRef } from 'react';
import './Header.css';

const Header = () => {
  const [activeSection, setActiveSection] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const menuRef = useRef(null);

  const sectionNames = useMemo(() => ({
    'vivo': 'Vivo',
    'albumes': 'Álbumes',
    'videos': 'Videos',
    'bio': 'Bio',
    'contacto': 'Contacto'
  }), []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = Object.keys(sectionNames);
      let current = '';

      for (let section of sections) {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 150 && rect.bottom >= 150) {
            current = section;
            break;
          }
        }
      }

      setActiveSection(current);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [sectionNames]);

  useEffect(() => {
    if (menuRef.current) {
      const activeLink = menuRef.current.querySelector('.active');
      if (activeLink) {
        setIndicatorStyle({
          left: `${activeLink.offsetLeft}px`,
          width: `${activeLink.offsetWidth}px`
        });
      }
    }
  }, [activeSection]);

  const handleClick = (e, sectionId) => {
    e.preventDefault();
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <img src="./arena-nerga-logo-horizontal.png" alt="Arena Negra" />
      </div>
      <button className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <nav className={`menu ${isMenuOpen ? 'open' : ''}`} ref={menuRef}>
        <div className="menu-indicator" style={indicatorStyle}></div>
        <ul>
          {Object.entries(sectionNames).map(([sectionId, sectionName]) => (
            <li key={sectionId}>
              <a 
                href={`#${sectionId}`}
                onClick={(e) => handleClick(e, sectionId)}
                className={activeSection === sectionId ? 'active' : ''}
              >
                {sectionName}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;