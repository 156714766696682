/* version v3 */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import DOMPurify from 'dompurify';
import './AlbumDetailsModal.css';
import { IoChevronDownOutline, IoPlaySharp, IoPauseOutline } from 'react-icons/io5';


const AlbumDetailsModal = ({ lyrics, onClose, title, albumData, tracks, currentTrackIndex, onTrackSelect, isPlaying, onPlayPause, trackDurations, albums, currentAlbumIndex, onAlbumSelect, initialTab, isWideScreen }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(initialTab || 'songs');

    const [hoveredTrack, setHoveredTrack] = useState(null);

    useEffect(() => {
        setIsOpen(true);
        setActiveTab(initialTab || 'songs');
    }, [initialTab]);

    const handleClose = () => {
        setIsOpen(false);
        setTimeout(onClose, 300);
    };
    const handleTrackSelect = useCallback((index) => {
        onTrackSelect(index);
      }, [onTrackSelect]);
      
    const handleAlbumSelect = (index) => {
        onAlbumSelect(index);
        setActiveTab('songs');  // Cambia a la pestaña de canciones después de seleccionar un álbum
    };
    const [indicatorStyle, setIndicatorStyle] = useState({});
    const navRef = useRef(null);

    const updateIndicatorPosition = useCallback(() => {
        if (navRef.current) {
            const activeButton = navRef.current.querySelector('.active');
            if (activeButton) {
                setIndicatorStyle({
                    left: `${activeButton.offsetLeft}px`,
                    width: `${activeButton.offsetWidth}px`
                });
            }
        }
    }, []);

    useEffect(() => {
        updateIndicatorPosition();
    }, [activeTab, updateIndicatorPosition]);

    useEffect(() => {
        updateIndicatorPosition();
        window.addEventListener('resize', updateIndicatorPosition);
        return () => window.removeEventListener('resize', updateIndicatorPosition);
    }, [updateIndicatorPosition]);


    const renderContent = () => {
        switch (activeTab) {
            case 'album':
                return (
                    <div className="albums-list">
                        {albums.map((album, index) => (
                            <div
                                className={`album-info ${index === currentAlbumIndex ? 'active' : ''}`}
                                key={index}
                                onClick={() => handleAlbumSelect(index)}
                            >
                                <img
                                    src={album.albumData.coverImage.node.sourceUrl}
                                    alt={album.title}
                                    className="album-cover"
                                />
                                <h3 className="album-title">{album.title}</h3>
                                <p className="album-year">{new Date(album.date).getFullYear()} | Álbum</p>
                            </div>
                        ))}
                    </div>
                );
            case 'songs':
                return (
                    <div className="songs-list">
                        {tracks.map((track, index) => (
                           <div
                           className={`song-info ${index === currentTrackIndex ? 'active' : ''}`}
                           key={index}
                           onClick={() => handleTrackSelect(index)}
                           onMouseEnter={() => setHoveredTrack(index)}
                           onMouseLeave={() => setHoveredTrack(null)}
                         >
                           <div className="song-number">
                             {index === currentTrackIndex ? (
                               isPlaying ? <IoPauseOutline onClick={(e) => { e.stopPropagation(); onPlayPause(); }} /> 
                                         : <IoPlaySharp onClick={(e) => { e.stopPropagation(); onPlayPause(); }} />
                             ) : (
                               hoveredTrack === index ? <IoPlaySharp /> : index + 1
                             )}
                           </div>
                                <figure><img src={albumData.coverImage.node.sourceUrl} alt={albumData.title} /></figure>
                                <div className="song-item">
                                    <div className='track-title'>
                                        {track.title}
                                    </div>
                                    <div className='track-duration'>
                                        <span className="song-duration">  {trackDurations[track.youtubeId] || `${track.duration}`}</span>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                );
            case 'lyrics':
                return <div className="lyrics-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(lyrics) }} />;
            default:
                return null;
        }
    };


    return (
        <div className={`modal-backdrop ${isOpen ? 'open' : ''} ${isWideScreen ? '' : 'mobile-view'}`} onClick={handleClose}>
            <div className={`modal-content ${isOpen ? 'open' : ''}`} onClick={e => e.stopPropagation()}>
                <div className="modal-header-nav">
                    <div className="modal-header">
                        <button onClick={handleClose} className="close-modal">
                            <IoChevronDownOutline />
                        </button>
                        <h2>{title}</h2>
                    </div>
                    <nav className="modal-nav" ref={navRef}>
                        <div className="nav-indicator" style={indicatorStyle}></div>
                        <button
                            className={activeTab === 'album' ? 'active' : ''}
                            onClick={() => setActiveTab('album')}
                        >
                            Álbum
                        </button>
                        <button
                            className={activeTab === 'songs' ? 'active' : ''}
                            onClick={() => setActiveTab('songs')}
                        >
                            Lista de canciones
                        </button>
                        <button
                            className={activeTab === 'lyrics' ? 'active' : ''}
                            onClick={() => setActiveTab('lyrics')}
                        >
                            Letra
                        </button>
                    </nav>
                </div>
                <div className="modal-body">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
};

export default AlbumDetailsModal;