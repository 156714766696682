import React, { useState, useEffect } from 'react';
import { IoLogoYoutube } from "react-icons/io5";
import './Live.css';

const Live = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    
    const images = [ 
        'http://arenanegra.com.uy/api/wp-content/uploads/arena-negra-puntorojo-oct-2024-fig1.jpeg',
        'http://arenanegra.com.uy/api/wp-content/uploads/arena-negra-puntorojo-oct-2024-fig2.jpeg',
        'http://arenanegra.com.uy/api/wp-content/uploads/arena-negra-puntorojo-oct-2024-fig3.jpeg',
        'http://arenanegra.com.uy/api/wp-content/uploads/arena-negra-puntorojo-oct-2024-fig4.jpeg',
        'http://arenanegra.com.uy/api/wp-content/uploads/arena-negra-puntorojo-oct-2024-fig5.jpeg',
        // Añade aquí más imágenes según necesites
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => 
                (prevIndex + 1) % images.length
            );
        }, 5000); // Cambia la imagen cada 5 segundos

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <main className='live' id="vivo">
            <div className='live-background'></div>
            <div className='live-content'>
                <div className='live-wrapper'>
                    <div className='carousel-container'>
                        {images.map((image, index) => (
                            <div
                                key={index}
                                className={`carousel-slide ${index === currentImageIndex ? 'active' : ''}`}
                                style={{ backgroundImage: `url(${image})` }}
                            />
                        ))}
                        <div className='live-overlay'>
                            <h2>¡REVIVE EL SHOW!</h2>
                            <p>Arena Negra en Punto Rojo</p>
                            <a 
                                href="https://www.youtube.com/watch?v=pGYpJDXbh2E" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="youtube-button"
                            >
                                <IoLogoYoutube />
                                Ver en YouTube
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Live;