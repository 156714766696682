import HomePage from './pages/homepage/HomePage';
import './App.css';
import { Routes, Route } from "react-router-dom"; 
import Error404 from './pages/error404/Error404';
import Explore from './explore/Explore';
import AlbumTemplate from './pages/album/AlbumTemplate';  
import Prueba from './pages/Prueba';
import MusicPlayer from './music-player/MusicPlayer';
import { MusicPlayerProvider } from './contexts/MusicPlayerProvider';
 

function App() {
  return ( 
    <MusicPlayerProvider> 
      <div className="app-container"> 
        <Routes>
          <Route path="/" element={<HomePage />} />   
          <Route path="/album/:slug" element={<AlbumTemplate />} />
          <Route path="/explora" element={<Explore />} />
          <Route path="/prueba" element={<Prueba />} />
          <Route path="*" element={<Error404 />} />
        </Routes>  
        <MusicPlayer />
      </div> 
    </MusicPlayerProvider>
  );
}

export default App;
