import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from "@apollo/client";
import client from './apollo-client'; // Importa el cliente Apollo

import { BrowserRouter } from "react-router-dom";  
import FontIcons from './components/design/fonts/FontIcons'; 

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  <React.StrictMode>
     <BrowserRouter>  
      <ApolloProvider client={client}>  
          <FontIcons>
            <App /> 
          </FontIcons> 
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
