import React, {useEffect} from 'react';

const Explore = () => {
  useEffect(() => {
    // Redirige a una URL externa
    window.location.href = 'https://linktr.ee/gianniarena';
  }, []);

  return <></>; // No se muestra nada en la página
};

export default Explore;