import React, { useEffect, useState } from 'react'
import './footer.css'

function Footer() {
    const [date, setDate] = useState();

    const getYear = () => setDate(new Date().getFullYear())

    useEffect(() => {
        getYear();
    }, [])

    return (
        <footer>
            <div>
                <div>
                    <p className='copyright'>Arena Negra ⚡ {date}</p>
                </div>
                <div className='SocialNetworks'> 
                    <div className='PlatformIcons'>
                        <a href='https://es-la.facebook.com/arena.negra.artista/' target='_blank' rel="noreferrer"><i className='icon-facebook-f'></i></a>
                        <a href='https://www.instagram.com/gianniarena' target='_blank' rel="noreferrer"><i className='icon-instagram'></i></a>
                        <a href="https://www.tiktok.com/@gianni.arena" target='_blank' rel="noreferrer"><i className='icon-tiktok'></i></a>
                        <a href='https://music.apple.com/us/artist/arena-negra/1479109149' target='_blank' rel="noreferrer"><i className='icon-apple'></i></a>
                        <a href='https://open.spotify.com/intl-es/artist/1MX8lmEO8yfRymlZjaasoM' target='_blank' rel="noreferrer"><i className='icon-spotify'></i></a>
                        <a href="https://www.amazon.com/music/player/artists/B07XJB7Q3B/arena-negra" target='_blank' rel="noreferrer"><i className='icon-amazon'></i></a>
                        <a href="https://music.youtube.com/channel/UCcduXa1joRMj44r_IBQMaWw?feature=gws_kp_artist&feature=gws_kp_artist" target='_blank' rel="noreferrer"><i className='icon-youtube'></i></a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
