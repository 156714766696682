// src/pages/homepage/VideoEmbed.jsx
import React, { useRef, useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import { useMusicPlayer } from '../../contexts/MusicPlayerProvider';
import './VideoEmbed.css'

const VideoEmbed = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { isVideoPlaying, setIsVideoPlaying, setIsPlaying, isPlayerPlaying, setIsPlayerPlaying } = useMusicPlayer();
  const videoId = 'HJIkYqGsQBc';

  const opts = {
    height: '315',
    width: '560',
    playerVars: {
      controls: 0,
      modestbranding: 1,
      rel: 0,
      autohide: 1
    },
  };

  useEffect(() => {
    const observerTarget = videoRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        if (!entry.isIntersecting && isVideoPlaying) {
          setIsVideoPlaying(false);
          if (playerRef.current) {
            playerRef.current.pauseVideo();
          }
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }
    );

    if (observerTarget) {
      observer.observe(observerTarget);
    }

    return () => {
      if (observerTarget) {
        observer.unobserve(observerTarget);
      }
    };
  }, [isVideoPlaying, setIsVideoPlaying]);

  useEffect(() => {
    if (playerRef.current) {
      if (isVideoPlaying && isVisible) {
        playerRef.current.playVideo();
        setIsPlaying(false);
        setIsPlayerPlaying(false);
      } else if (!isVideoPlaying && isVisible) {
        playerRef.current.pauseVideo();
      }
    }
  }, [isVideoPlaying, isVisible, setIsPlaying, setIsPlayerPlaying]);

  // Nuevo efecto para manejar cuando isPlayerPlaying cambia
  useEffect(() => {
    if (isPlayerPlaying && playerRef.current) {
      playerRef.current.pauseVideo();
      setIsVideoPlaying(false);
    }
  }, [isPlayerPlaying, setIsVideoPlaying]);

  const onReady = (event) => {
    playerRef.current = event.target;
  };

  const onStateChange = (event) => {
    if (event.data === YouTube.PlayerState.PLAYING) {
      setIsVideoPlaying(true);
      setIsPlaying(false);
      setIsPlayerPlaying(false);
    } else if (event.data === YouTube.PlayerState.PAUSED) {
      setIsVideoPlaying(false);
    }
  };


  return (
    <div className="video-container" id="videos" ref={videoRef}>
      <YouTube
        videoId={videoId}
        opts={opts}
        onReady={onReady}
        onStateChange={onStateChange}
      />
    </div>
  );
};

export default VideoEmbed;