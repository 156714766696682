import React from 'react';
import './Error404.css'; // Archivo de estilos CSS para la página de error

function Error404() {
  return (
    <div className="error-container">
      <h1 className="error-heading">Error 404</h1>
      <p className="error-message">Lo sentimos, la página que estás buscando no existe.</p> 
      <a className="home-link" href="/">Ir a la página de inicio</a>
    </div>
  );
}

export default Error404;
