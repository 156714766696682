import React, { useRef } from 'react';
import { CiMail, CiLinkedin } from "react-icons/ci";

import emailjs from '@emailjs/browser';
import './Contact.css';

function Contact() {
    const form = useRef();


    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                form.current,
                {
                    publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
                }
            )
            .then(
                (result) => {
                    console.log('SUCCESS!', result);
                    alert('Mensaje enviado con éxito');
                    form.current.reset();
                },
                (error) => {
                    console.error('FAILED...', error);
                    alert(`Error al enviar el mensaje: ${JSON.stringify(error)}`);
                }
            );
    };


    return (
        <div className='contact-section' id="contacto"> 
            <div className='contact-info'>  
                <h3 className='contact-title'>
                    Para consultas y contrataciones, contacta a nuestro manager:
                </h3>
                <figure className='contact-image'><img src="./images/leociarlariello.jpeg" alt="Leonardo Ciarlariello"></img></figure>
                 
                <p>Mario Leonardo Ciarlariello</p>
                <p><a className='contact-linkedin' href="https://www.linkedin.com/in/mario-leonardo-ciarlariello-b4b15b17/" target="_blank" rel="noreferrer"><CiLinkedin/> LinkedIn</a></p>
                <a href="mailto:leo@arenanegra.com.uy" className='email-link'>
                    <CiMail /> leo@arenanegra.com.uy
                </a>
                 
            </div>
            <form ref={form} className='contact-form' onSubmit={sendEmail}>
                <input
                    type="text"
                    name="user_name"
                    placeholder="Nombre"
                    required
                />
                <input
                    type="email"
                    name="user_email"
                    placeholder="Correo electrónico"
                    required
                />
                <textarea
                    name="message"
                    placeholder="Mensaje"
                    required
                ></textarea>
                <button type="submit">Enviar</button>
            </form>

        </div>
    );
}

export default Contact;