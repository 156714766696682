import React from 'react';
import './Bio.css'; // Asegúrate de crear este archivo CSS
import backgroundImage from '../../assets/images/arena-negra-banda-2024.jpeg'; // Ajusta la ruta según sea necesario


function Bio() {
    return (
        <div id='bio' className='bio-section' style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className='bio-content'>
                <h2>Biografía</h2>
                <p>Gianni Arena, un apasionado músico y compositor uruguayo, ha dado vida a su proyecto musical en solitario bajo el nombre de Arena Negra.</p>
                <p>Trabajando en estrecha colaboración con el talentoso productor musical Pablo Faragó, Gianni ha logrado materializar su visión artística con el lanzamiento de su primer álbum de Arena Negra el 9 de setiembre de 2019.</p>
                <p>En julio de 2023 publicó su segundo álbum "Entre mundos y libertad". Presenta una evolución en el sonido y una exploración aún más profunda de temas universales como el amor, la libertad y la búsqueda de identidad.</p>
                <p>Arena Negra cobra vida en el escenario junto a los talentosos músicos que acompañan a Gianni: Mauro Clavijo en batería, Santiago Casaretto en el bajo, y Sebastián Atienza en guitarra.</p>
            </div>
        </div>
    );
}

export default Bio;