import React, { useRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function MyAlbums() {
    const homepageRef = useRef(null);
    const [isComponentMounted, setIsComponentMounted] = useState(false);

    useEffect(() => {
        setIsComponentMounted(true);
        return () => setIsComponentMounted(false);
    }, []);

    useEffect(() => {
        if (!isComponentMounted) return;

        const homepage = homepageRef.current;
        if (!homepage) return;

        let rafId = null;

        const handleParallax = (e) => {
            if (rafId) {
                cancelAnimationFrame(rafId);
            }

            rafId = requestAnimationFrame(() => {
                if (!homepage) return;

                const rect = homepage.getBoundingClientRect();
                const centerX = rect.left + rect.width / 2;
                const centerY = rect.top + rect.height / 2;
                const moveX = (e.clientX - centerX) / 100;
                const moveY = (e.clientY - centerY) / 100;

                homepage.style.setProperty('--moveX', `${moveX}px`);
                homepage.style.setProperty('--moveY', `${moveY}px`);
            });
        };

        const handleScroll = () => {
            if (!homepage) return;

            const scrollY = window.scrollY;
            homepage.style.setProperty('--scrollY', `${scrollY * 0.05}px`);
        };

        document.addEventListener('mousemove', handleParallax);
        window.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('mousemove', handleParallax);
            window.removeEventListener('scroll', handleScroll);
            if (rafId) {
                cancelAnimationFrame(rafId);
            }
        };
    }, [isComponentMounted]);

    return (
        <div className='homepage' id="albumes" ref={homepageRef}>
            <div>
                <h2>Álbumes</h2>
                <main className='Album'>
                    <div>
                        <div className='album-cover'>
                            <figure><img src="./images/entre-mundos-y-libertad.jpg" alt="álbum" /></figure>
                            <Link to="/album/entre-mundos-y-libertad">Escuchar álbum</Link>
                        </div>
                        <p>(2023)</p>
                    </div>
                    <div>
                        <div className='album-cover'>
                            <figure><img src="./images/album-arena-negra.jpeg" alt="álbum" /></figure>
                            <Link to="/album/arena-negra">Escuchar álbum</Link>
                        </div>
                        <p>(2019)</p>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default MyAlbums